<template>
    <div id="supervisor-blog">

        <b-overlay :show="showLoading" spinner-variant="primary">

            <div class="row">

                <div class="col-md-4" :key="picKey">
                    <div class="card">
                        <img v-if="isEdit" :src="blog.image" class="card-img-top" alt="Blog Image">
                        <img v-else :src="blog.image == '' ? 'https://via.placeholder.com/400x200' : blog.image"
                            class="card-img-top" alt="Blog Image">
                        <div class="card-body">
                            <b-button variant="relief-success" class="mr-50" @click="$refs.refInputEl.click()">
                                <feather-icon icon="CloudIcon" class="mr-50" />
                                <span class="align-middle">Importer</span>
                                <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer">
                            </b-button>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <b-card>
                        <b-row>
                            <b-col cols="12">
                                <b-form-group label="Titre">
                                    <b-form-input v-model="blog.title" placeholder="Titre" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group label="Description">
                                    <b-form-input v-model="blog.description" placeholder="Description" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <quill-editor v-model='blog.details'></quill-editor>
                            </b-col>
                        </b-row>
                    </b-card>
                </div>
            </div>

            <hr>

            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="relief-warning" class="mr-4" @click="resetData">
                        <feather-icon icon="RefreshCwIcon" class="mr-50" />
                        <span class="align-middle">Reset</span>
                    </b-button>
                    <b-button variant="relief-success" @click="saveData">
                        <feather-icon icon="PocketIcon" class="mr-50" />
                        <span class="align-middle">Save</span>
                    </b-button>
                </b-col>
            </b-row>

        </b-overlay>

    </div>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import {
    quillEditor
} from 'vue-quill-editor';

export default {
    components: {
        quillEditor
    },
    data() {
        return {
            isEdit: false,
            picKey: 0,
            showLoading: false,
            originBlog: {
                image: "",
                title: "",
                description: "",
                details: "",
                imageFileName: ""
            },
            blog: {
                image: "",
                title: "",
                description: "",
                details: "",
                imageFileName: ""
            }
        }
    },
    created() {
        if (this.$route.params.id) {
            this.isEdit = true;
            this.loadData();
        }
    },
    methods: {
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.get("blog/" + this.$route.params.id).catch(() => this.showLoading = false);
            response.data.image = this.$serverpath + response.data.image;
            this.originBlog = response.data;
            this.blog = { ...this.originBlog };
            this.showLoading = false;
        },
        resetData() {
            this.blog = { ...this.originBlog };
        },
        async saveData() {
            this.showLoading = true;
            try {
                await this.$http.post("blog", this.blog);
                this.$router.push({ name: "supervisor-website-blog-list" });
            } catch (error) {
                // Handle the error and stop further execution
                console.error(error);
            } finally {
                // Hide the loading indicator regardless of success or failure
                this.showLoading = false;
            }
        },
        inputImageRenderer(event) {
            // Assuming event.target.files[0] is the selected image file
            const selectedImageFile = event.target.files[0];
            this.blog.imageFileName = selectedImageFile.name;
            if (selectedImageFile) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    // Update the avatar source when a new image is selected
                    const binaryString = e.target.result;
                    // console.log(binaryString)
                    // let filestring = btoa(binaryString);
                    // console.log(filestring)
                    this.blog.image = binaryString;
                    // let filestring = btoa(binaryString);
                    this.picKey += 1;
                };
                reader.readAsDataURL(selectedImageFile);
            }
        },
    },
}
</script>

<style scoped>
.header-image {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('~@/assets/images/website/page-header-bg.jpg') no-repeat center center;
    background-size: cover;
    color: white;
    padding: 50px 0;
}

.actuality {
    padding: 50px;
}
</style>